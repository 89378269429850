window._ = require('lodash');

try {
  window.Popper    = require('popper.js').default;
  window.$         = window.jQuery = require('jquery');
  window.bootstrap = require('bootstrap');
  window.Swal      = require('sweetalert2');
  require( 'datatables.net-bs4' )(window, $);
  require( 'datatables.net-responsive-bs4' );
} catch (e) {}

window.toastr = require('toastr');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

jQuery.each( [ "put", "delete" ], function( i, method ) {
  jQuery[ method ] = function( url, data, callback, type ) {
    if ( jQuery.isFunction( data ) ) {type = type || callback;callback = data;data = undefined;}
    return jQuery.ajax({url: url,type: method,dataType: type,data: data,success: callback});
  };
});
jQuery.ajaxSetup({
  headers: {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")},
  error : (jqXHR, textStatus, errorThrown) => console.log(jqXHR)
});
new bootstrap.Tooltip(document.body, {selector: '[data-bs-toggle="tooltip"]'});

const send_form_by_ajax = (form_id, success) => {
  const form = document.querySelector(form_id);
  form.addEventListener('submit', (event) => {
    event.preventDefault();
    Object.values(form.querySelectorAll('input.is-invalid') ?? {}).forEach(ele => ele.classList.remove('is-invalid'));
    Object.values(form.querySelectorAll('span[role="alert"]') ?? {}).forEach(ele => ele.innerHTML = null);

    const formData = new FormData(form);
    axios.post(form.action, formData)
    .then((response) => {success(response)})
    .catch((error) => Object.entries(error.response.data.errors).forEach(([key, value]) => {
      var ele = form.querySelector(`[name="${key}"]`);
      ele.classList.add('is-invalid');
      ele.parentNode.querySelector('[role="alert"]').innerHTML = value;
    }));
  });
}

const json_to_tr = (jsonData, elementToBind) => {
  //This Code gets all columns for header   and stored in array col
  var col = [];
  for (var i = 0; i < jsonData.length; i++) {
    for (var key in jsonData[i]) {
      if (col.indexOf(key) === -1) {
        col.push(key);
      }
    }
  }
  const table = document.querySelector(elementToBind);
  table.innerHTML = "";
  thead = document.createElement('thead');
  tbody = document.createElement('tbody');
  table.append(thead, tbody);
  //This Code getsrows for header creader above.
  var tr = thead.insertRow(-1);
  for (var i = 0; i < col.length; i++) {
      var th = document.createElement("th");
      th.innerHTML = col[i];
      tr.appendChild(th);
  }
  //This Code adds data to tbody as rows
  for (var i = 0; i < jsonData.length; i++) {
    tr = tbody.insertRow(-1);
    for (var j = 0; j < col.length; j++) {
      var tabCell = tr.insertCell(-1);
      tabCell.innerHTML = jsonData[i][col[j]];
    }
  }

  // //This Code gets the all columns for header
  // var divContainer = document.querySelector(elementToBind);
  // divContainer.innerHTML = "";
  // divContainer.appendChild(tbody);
}

window.json_to_tr = json_to_tr;

if (document.readyState === 'complete') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => init());
}

const init = () => {
  if (document.querySelector(`#login_form`)) {send_form_by_ajax(`#login_form`, () => window.location.reload());}
  var path = window.location.pathname;
  switch (true) {
    case (/^\/gacha\/(\w+\/?)+$/).test(path):
      send_form_by_ajax('#redeem_form', (response) => {document.querySelector('#redeemed_content') ? document.querySelector('#redeemed_content').remove() : null; document.querySelector('#redeem_form').parentElement.insertAdjacentHTML('beforeend', response.data.html)});
      break;
    default:
      break;
  }
}
